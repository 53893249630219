import React, { createContext, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { appConfig } from "../config";

interface AuthContextType {
  accessToken: string | null;
  setAccessToken: (token: string | null) => void;
  // Method for conditional redirection
  redirectAuth: () => void;
  // Method to refresh tokens
  refreshAccessToken: () => Promise<string | null>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem("auth_access_token")
  );
  const navigate = useNavigate();
  const location = useLocation();

  // Method to check if the user is authenticated and redirect if not
  const redirectAuth = () => {
    const isLoginPage = location.pathname === "/login";

    if (!accessToken && !isLoginPage) {
      const redirectUrl = encodeURIComponent(
        location.pathname + location.search + location.hash
      );
      navigate(`/login?redirect=${redirectUrl}`);
    }
  };

  // Function to refresh access token using the provided API
  const refreshAccessToken = async (): Promise<string | null> => {
    try {
      const response = await fetch(`${appConfig.warden_url}/refresh`, {
        method: "POST",
        headers: {
          accept: "application/json",
        },
        credentials: "include", // Ensures cookies (like HTTP-only refresh token) are sent
      });

      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.access_token); // Update the access token
        localStorage.setItem("auth_access_token", data.access_token);
        return data.access_token;
      } else {
        console.warn("Failed to refresh access token");
      }
    } catch (error) {
      console.error("Error during token refresh:", error);
    }
    return null;
  };

  return (
    <AuthContext.Provider
      value={{ accessToken, setAccessToken, redirectAuth, refreshAccessToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
