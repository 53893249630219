import { Button, ButtonProps } from "@mui/material";

export interface WootButtonProps extends ButtonProps {
  sx?: ButtonProps["sx"];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
}

const buttonStyle = {
  borderRadius: "50px",
  fontWeight: "bold",
  backgroundColor: "secondary.main",
  color: "common.black",
  padding: "10px 20px",
  "&:hover": {
    backgroundColor: "secondary.light",
  },
};

const WootButton = ({ sx, onClick, children, ...props }: WootButtonProps) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        ...buttonStyle,
        ...sx, // Merge with any additional styles passed through sx prop
      }}
      {...props} // Spread the rest of the props (like onClick, disabled)
    >
      {children}
    </Button>
  );
};

export default WootButton;
