interface AppConfig {
  warden_url: string;
  aura_url: string;
  discordLinkRedirect: string;
}

let config: AppConfig;
const environment = process.env.REACT_APP_ENV;
if (environment === "prod") {
  config = {
    warden_url: "https://warden.loopystudios.com",
    aura_url: "https://aura.loopystudios.com",
    discordLinkRedirect: "https%3A%2F%2Fwww.wootnewts.com%2Flink-discord",
  };
} else if (environment === "stg") {
  config = {
    warden_url: "https://warden-stg.loopystudios.com",
    aura_url: "https://aura-stg.loopystudios.com",
    discordLinkRedirect: "https%3A%2F%2Fstg.wootnewts.com%2Flink-discord",
  };
} else if (environment === "dev") {
  config = {
    warden_url: "https://warden-dev.loopystudios.com",
    aura_url: "https://aura-dev.loopystudios.com",
    discordLinkRedirect: "https%3A%2F%2Fdev.wootnewts.com%2Flink-discord",
  };
} else {
  // Local
  config = {
    warden_url: "https://warden-dev.loopystudios.com",
    aura_url: "https://aura-dev.loopystudios.com",
    discordLinkRedirect:
      "https%3A%2F%2Flocalhost.loopystudios.com%3A3000%2Flink-discord",
  };
}

export const appConfig = config;
