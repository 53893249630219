import React from "react";
import { Box, BoxProps } from "@mui/material"; // Import Box for layout
import logo from "../assets/logo.svg";

interface WootLogoProps {
  sx?: BoxProps["sx"];
  className?: string;
}

const WootLogo: React.FC<WootLogoProps> = ({ sx, className }) => {
  return (
    <Box
      className={className}
      sx={{
        display: "flex",
        justifyContent: "center", // Center the logo horizontally
        alignItems: "center", // Center the logo vertically
        padding: "20px", // Add padding around the logo
        maxWidth: "100%", // Make the container responsive
        overflow: "hidden", // Prevent overflow
        ...sx, // Merge with any additional styles passed through sx prop
      }}
    >
      <img
        src={logo}
        alt="Woot Newts Logo"
        loading="lazy" // Improve performance by lazy loading the image
        onError={(e) => {
          e.currentTarget.onerror = null; // Prevent infinite loop
          e.currentTarget.src = "fallback-image-url.png"; // Provide a fallback image URL
        }}
        style={{
          maxWidth: "100%", // Ensure logo is responsive
          height: "auto", // Maintain aspect ratio
        }}
      />
    </Box>
  );
};

export default WootLogo;
