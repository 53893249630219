import React from "react";
import "./HomePage.css";
import WootButton from "../components/WootButton";
import WootSignOutButton from "../components/WootSignoutButton";
import { Link } from "react-router-dom";
import { Alert, Box, CircularProgress } from "@mui/material";
import { appConfig } from "../config";
import WootLogo from "../components/WootLogo";
import { useAccount } from "../contexts/AccountContext";

export default function HomePage() {
  const { accountData, isCreated, isLoggedIn, loading, error } = useAccount();

  console.log(loading);
  const handleViewAccountData = () => {
    if (accountData) {
      alert(JSON.stringify(accountData, null, 2)); // Display account data in an alert
    }
  };

  return (
    <Box
      className="App"
      sx={{
        textAlign: "center",
        padding: "20px",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <WootLogo
        className="App-logo"
        sx={{ width: "40vmin", height: "40vmin" }}
      />
      {error && <Alert severity="error">{error}</Alert>}
      {loading ? (
        <CircularProgress
          size={20}
          color="secondary"
          style={{
            marginRight: 8, // Add some space between the spinner and text
          }}
        />
      ) : (
        !isLoggedIn && (
          <Box
            sx={{
              display: "grid",
              gridAutoFlow: "column",
              gridColumnGap: "10px",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Link to="/login">
              <WootButton>Sign In</WootButton>
            </Link>
          </Box>
        )
      )}
      {isLoggedIn && (
        <Box
          sx={{
            display: "grid",
            gridAutoFlow: "column",
            gridColumnGap: "10px",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          {(isCreated && (
            <>
              <WootButton onClick={handleViewAccountData}>
                View Account Data
              </WootButton>
              <Link
                target="_blank"
                to={`https://discord.com/oauth2/authorize?response_type=token&client_id=1232057687869493372&scope=identify&redirect_uri=${appConfig.discordLinkRedirect}`}
              >
                <WootButton>Link Discord</WootButton>
              </Link>
            </>
          )) ||
            "You don't have an account."}
          <WootSignOutButton />
        </Box>
      )}
    </Box>
  );
}
