import React, { useState } from "react";
import { Box, TextField, Grid, Alert } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { appConfig } from "../../config";
import { useAuth } from "../../contexts/AuthContext";
import WootForm from "../WootForm/WootForm";
import { LoginMenu } from "./WootLogin";

interface WootSigninFormProps {
  emailState: [string, React.Dispatch<React.SetStateAction<string>>];
  menuState: [LoginMenu, React.Dispatch<React.SetStateAction<LoginMenu>>];
}

function WootSigninForm({ emailState, menuState }: WootSigninFormProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAccessToken } = useAuth();
  const [email, setEmail] = emailState;
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [, setMenuState] = menuState;

  const handleLogin = async () => {
    setError("");
    setSuccess(false);

    try {
      const response = await fetch(`${appConfig.warden_url}/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      // 409 CONFLICT if the user has not been verified by email.
      if (response.status === 409) {
        setMenuState(LoginMenu.VerifyEmail);
      }

      if (!response.ok) {
        // Extract the response text for error message
        const errorText = await response.text();
        throw new Error(errorText || "Failed to login");
      }

      const result = await response.json();
      console.log("Access Token:", result.access_token);
      console.log("Refresh Token:", result.refresh_token);

      // Store only the access token in local storage.
      // The refresh token is set as an http-only cookie.
      localStorage.setItem("auth_access_token", result.access_token);
      setAccessToken(result.access_token);

      setSuccess(true); // Login successful

      // Read redirect URL from query parameters
      const redirectUrl = new URLSearchParams(location.search).get("redirect");
      if (redirectUrl) {
        navigate(decodeURIComponent(redirectUrl)); // Redirect back
      } else {
        navigate("/"); // Redirect to home if no redirect URL
      }
    } catch (err) {
      if (err instanceof Error) {
        // If the error is of type Error, handle it safely
        setError(err.message || "An unknown error occurred");
      } else {
        // Fallback for other types of errors
        setError("An unexpected error occurred");
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">Login successful!</Alert>}
      <WootForm onSubmit={handleLogin} sx={{ mt: 1 }} submitText="Login">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          placeholder="john@smith.com"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          placeholder="abc123"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            sx: { color: "text.secondary" },
          }}
        />
      </WootForm>
      <Grid container>
        <Grid item xs>
          <Link
            to="#"
            style={{
              textDecoration: "none",
            }}
            onClick={() => {
              setMenuState(LoginMenu.ForgotPasswordForm);
            }}
          >
            {"Forgot password?"}
          </Link>
        </Grid>
        <Grid item>
          <Link
            to="#"
            style={{
              textDecoration: "none",
            }}
            onClick={() => {
              setMenuState(LoginMenu.Signup);
            }}
          >
            {"No account? Sign Up"}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default WootSigninForm;
