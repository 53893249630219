import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./AuthContext";
import { appConfig } from "../config";

interface AccountContextType {
  accountData: any;
  isLoggedIn: boolean;
  isCreated: boolean;
  error: string | null;
  loading: boolean;
}

const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const AccountProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { accessToken, refreshAccessToken } = useAuth();
  const [accountData, setAccountData] = useState<any>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchAccountData = async (token: string) => {
    try {
      const response = await fetch(`${appConfig.aura_url}/account/get`, {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Account exists
        const data = await response.json();
        setAccountData(data);
        setIsCreated(true);
        setIsLoggedIn(true);
        setError(null);
      } else if (response.status === 400) {
        // Account does not exist
        setError(null);
        setIsCreated(false);
        setIsLoggedIn(true);
      } else if (response.status === 401) {
        // Bad access token
        setError("Unauthorized");
        setIsCreated(false);
        setIsLoggedIn(false);
      } else {
        // Unexpected result
        const errorText = await response.text();
        throw new Error(errorText || "Failed to retrieve account");
      }
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message || "An unknown error occurred");
      } else {
        setError("An unexpected error occurred");
      }
      setIsCreated(false);
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    const getAccountData = async () => {
      setLoading(true);
      try {
        if (accessToken) {
          await fetchAccountData(accessToken);
        } else {
          const newAccessToken = await refreshAccessToken();
          if (newAccessToken) {
            await fetchAccountData(newAccessToken);
          }
        }
      } finally {
        setLoading(false);
      }
    };
    getAccountData();
  }, [accessToken, refreshAccessToken]);

  return (
    <AccountContext.Provider
      value={{ accountData, isCreated, isLoggedIn, error, loading }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccount must be used within an AccountProvider");
  }
  return context;
};
