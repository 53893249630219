import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";

export default function AuthTestPage() {
  const { accessToken } = useAuth();

  return (
    <div className="App">
      {accessToken ? (
        <p>
          Your access token is {accessToken}.{" "}
          <Link to="/logout?redirect=/token">Log out</Link>
        </p>
      ) : (
        <p>
          You're not logged in. <Link to="/login?redirect=/token">Log in</Link>
        </p>
      )}
    </div>
  );
}
