import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./page_components/HomePage";
import VerifyDiscordPage from "./page_components/VerifyDiscordPage";
import LoginPage from "./page_components/LoginPage";
import { AuthProvider } from "./contexts/AuthContext";
import AuthTestPage from "./page_components/AuthTestPage";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./Theme";
import { AccountProvider } from "./contexts/AccountContext";

export const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/token" element={<AuthTestPage />} />
    <Route path="/link-discord" element={<VerifyDiscordPage />} />
    <Route path="*" element={<HomePage />} />
  </Routes>
);

export function App() {
  return (
    <BrowserRouter basename="/">
      <AuthProvider>
        <AccountProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppRoutes />
          </ThemeProvider>
        </AccountProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
