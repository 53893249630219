import React from "react";
import { appConfig } from "../config";
import { useAuth } from "../contexts/AuthContext";
import WootForm from "./WootForm/WootForm";

function WootSignOutButton() {
  const { setAccessToken } = useAuth();

  const handleLogout = async () => {
    const response = await fetch(`${appConfig.warden_url}/signout`, {
      method: "GET",
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Please try again.");
    }

    // Clear auth token
    localStorage.removeItem("auth_access_token");
    setAccessToken(null); // Clear the access token in context

    // Refresh the page after logout
    window.location.reload();
  };

  return <WootForm onSubmit={handleLogout} submitText="Signout"></WootForm>;
}

export default WootSignOutButton;
