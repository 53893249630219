import { Box, BoxProps, Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import WootFormSubmitButton from "./WootFormSubmitButton";

interface WootFormProps {
  sx?: BoxProps["sx"];
  submitText?: string;
  onSubmit: () => Promise<void>; // Expecting a function returning a Promise
  children?: React.ReactNode;
}

function WootForm({
  sx,
  submitText,
  onSubmit,
  children,
  ...props
}: WootFormProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await onSubmit(); // Call the passed onSubmit prop
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setError(null); // Close Snackbar when dismissed
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={sx} {...props}>
      {children} {/* Render form fields and buttons */}
      <WootFormSubmitButton
        fullWidth
        loading={loading}
        variant="contained"
        sx={(children && { mt: 3, mb: 2 }) || {}}
      >
        {submitText || "Submit"}
      </WootFormSubmitButton>
      {/* Error Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error" onClose={handleCloseSnackbar}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default WootForm;
